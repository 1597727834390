import { Link } from "react-router-dom"
import moment from 'moment'
import * as dayjs from 'dayjs'
import "dayjs/locale/es"
dayjs().format()
dayjs.locale('es')

function BlogCardSearch({data,index}){
  console.log(data)
    return(
      <div key={data.title} className="bg-gray-200 shadow-card">
        <div className="h-52 w-full">
          <img
            className="h-full w-full object-cover"
            src={`${process.env.REACT_APP_API_URL}/${data.thumbnail}`}
            alt="" />
        </div>
        <div className='card-body px-4 py-6'>
          <p className="text-xl font-semibold text-black">{data.title}</p>
          <a href={data.href} className="block">
          <span className='capitalize'>{dayjs(data.published).format('MMM DD, YYYY')}</span>
          <p className="mt-3 text-base text-gray-500">{data.description}</p>
          </a>
          <div className="flex justify-center mt-2">
            <Link to={`/blog/${data.slug}`}>
              <span className="bg-gray-500 text-white px-7 py-2">Ver post</span>
            </Link>
          </div>
        </div>
      </div>
    )
}
export default BlogCardSearch