import React, { useState } from "react";
import AboutMeImg from "../../assets/img/aboutme.png";

export default function AboutMe() {
  return (
    <div
      className="flex pt-48 pb-8 bg-[left_-5rem_center] bg-no-repeat bg-cover bg-arvey md:py-24 md:bg-[top_center]"
      id="sobremi"
    >
      <div className="container mx-auto max-w-7xl">
        <div className="flex-col justify-center hidden w-1/2 py-4 ml-auto md:flex lg:py-10">
          <h2 className="text-4xl font-extrabold text-center text-white letraborde2 lg:text-5xl mb-3">
            SOBRE MI
          </h2>
          <div className="px-4 text-sm leading-normal tracking-tighter text-white lg:text-lg md:text-md">
            <p className="mb-4">
              Soy Arvey Duque Villamizar (Bucaramanga, 28 de mayo de 1972), empresario e industrial colombiano, socio fundador y presidente de la Comercializadora Internacional Empaquetadora del Norte SAS BIC, domiciliada en Cúcuta, capital del departamento Norte de Santander, Colombia. Esta sociedad comercial es destacada como una de las empresas más importantes en el sector de agroalimentos de la región.
            </p>
            <p className="mb-4">
              Junto con la sociedad comercial somos los fundadores de otras organizaciones como la Fundación Arduvi cuyo objeto social y principal actividad es la inclusión con amor a la vida normal de personas con discapacidad intelectual, física, y síndrome de down, vinculados laboralmente en el Café Arduvi, establecimiento que funciona en la ciudad de Cúcuta.
            </p>
            <p className="mb-4">
              En mi Misión, Filosofía, Valores, Responsabilidad y Ética Empresarial, la CI Empaquetadora del Norte SAS BIC realiza inversión social como práctica empresarial dirigida al apoyo a jóvenes deportistas de Norte de Santander en diferentes disciplinas como el ciclismo, atletismo, triatlonismo.
            </p>
            <p>
              También han llevado a cabo campañas para tratamientos de rehabilitación a personas con discapacidad física, destacando el realizado con Isabella Salazar, niña que sufre de diplejía espástica, cuyo proceso clínico de recuperación se trata con células madre en centros especializados de la ciudad de Bogotá.
            </p>
          </div>
        </div>
        <div className="sm:block md:hidden">
          <div>
            <p className="mb-4 text-white px-4">
              Soy Arvey Duque Villamizar (Bucaramanga, 28 de mayo de 1972), empresario e industrial colombiano, socio fundador y presidente de la Comercializadora Internacional Empaquetadora del Norte SAS BIC.
            </p>
          </div>
          <div className="w-full py-2 mx-auto text-center">
            <label htmlFor="my_modal_6" className="px-4 py-2 text-white rounded colorprincipal hover:bg-black">Ver más</label>
          </div>
          <input type="checkbox" id="my_modal_6" className="modal-toggle" />
          <div className="modal" role="dialog">
            <div className="modal-box bg-white">
              <h3 className="font-bold text-lg">Sobre mi</h3>
              <p className="mb-4">
                Soy Arvey Duque Villamizar (Bucaramanga, 28 de mayo de 1972), empresario e industrial colombiano, socio fundador y presidente de la Comercializadora Internacional Empaquetadora del Norte SAS BIC, domiciliada en Cúcuta, capital del departamento Norte de Santander, Colombia. Esta sociedad comercial es destacada como una de las empresas más importantes en el sector de agroalimentos de la región.
              </p>
              <p className="mb-4">
                Junto con la sociedad comercial somos los fundadores de otras organizaciones como la Fundación Arduvi cuyo objeto social y principal actividad es la inclusión con amor a la vida normal de personas con discapacidad intelectual, física, y síndrome de down, vinculados laboralmente en el Café Arduvi, establecimiento que funciona en la ciudad de Cúcuta.
              </p>
              <p className="mb-4">
                En mi Misión, Filosofía, Valores, Responsabilidad y Ética Empresarial, la CI Empaquetadora del Norte SAS BIC realiza inversión social como práctica empresarial dirigida al apoyo a jóvenes deportistas de Norte de Santander en diferentes disciplinas como el ciclismo, atletismo, triatlonismo.
              </p>
              <p>
                También han llevado a cabo campañas para tratamientos de rehabilitación a personas con discapacidad física, destacando el realizado con Isabella Salazar, niña que sufre de diplejía espástica, cuyo proceso clínico de recuperación se trata con células madre en centros especializados de la ciudad de Bogotá.
              </p>
              <div className="modal-action">
                <label htmlFor="my_modal_6" className="btn">Cerrar</label>
              </div>
            </div>
          </div>
        </div>
        </div>
    </div>
  );
}
