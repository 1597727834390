import SmallSetPaginationSearch from "components/pagination/SmallSetPaginationSearch"
import BlogCardSearch from "../BlogCardSearch"

function BlogList({posts, get_blog_list_page, count}){
  return(
    <div className="overflow-hidden px-8">
			<div className="grid my-8 grid-cols-1 lg:grid-cols-3 md:grid-cols-2 lg:gap-5">
        {posts && posts.map((post, index) => (
          <BlogCardSearch data={post} key={index} index={index}/>
        ))}
      </div>
      <SmallSetPaginationSearch
        list_page={get_blog_list_page}
        list={posts}
        count={count}
      />
    </div>
  )
}
export default BlogList