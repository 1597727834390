import ArveyImg from '../../assets/img/hero-img.jpg'
import ArveyImgMask from '../../assets/img/hero-img-1.png'

function Header() {
  return (
    <main>
      <div className="flex items-center min-h-screen bg-no-repeat bg-cover bg-background" id="inicio">
        <div className="container mx-auto max-w-7xl">
          <div className="flex flex-col items-center h-full lg:flex-row">
            <div className="flex justify-center lg:w-1/2 order-1 lg:order-2">
              <img src={ArveyImgMask} alt="Arvey Duque" className="hidden lg:flex"/>
              <img src={ArveyImg} alt="Arvey Duque" className="flex lg:hidden"/>
            </div>
            <div className="order-1 w-full lg:w-1/2 lg:order-1 px-4 py-4 lg:py-0 lg:px-6">
              <h1 className='text-center lg:text-left'>
                <span className="text-3xl font-extrabold text-white arveyduque md:text-2xl lg:text-5xl">ARVEY DUQUE</span>
                <br />
                <span className="text-3xl font-bold text-left letraborde villamizar md:text-2xl lg:text-5xl">VILLAMIZAR</span>
              </h1>
              <p className="py-6 tracking-tighter text-center md:text-left text-white text-lg md:text-xl">
                Empresario e industrial colombiano, pionero en inclusión, desarrollo y avance económico en Norte de Santander.
              </p> 
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default Header;