import { ArrowLeftCircleIcon, ArrowRightCircleIcon } from '@heroicons/react/24/solid'
import { useState } from 'react';

function SmallSetPagination({list_page, list, count}){

    const [active, setActive] = useState(1);
    const [listingsPerPage, ] = useState(6);
    const [currentPage, setCurrentPage] = useState(1);

    const totalPages = Math.ceil(count / listingsPerPage);

    const visitPage = (page) => {
        window.scrollTo(0, 0);
        setCurrentPage(page);
        setActive(page);
        list_page(page)
    }

    const previous_number = () => {
        window.scrollTo(0, 0);
        if (currentPage > 1) {
            visitPage(currentPage - 1);
        }
    };

    const next_number = () => {
        window.scrollTo(0, 0);
        if (currentPage < totalPages) {
            visitPage(currentPage + 1);
        }
    };

    let numbers = [];

    const getNumbers = () => {
        for (let page = 1; page <= totalPages; page++) {
            const content = (
                <div key={page} onClick={() => visitPage(page)} className={`hidden md:-mt-px md:flex`}>
                    <div className={`cursor-pointer ${active === page ? 'border-gray-700 text-gray-700' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'} border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium`}>
                        {page}
                    </div>
                </div>
            );
            numbers.push(content);
        }

        return numbers;
    }

    return(
        <nav className="border-t border-gray-200 px-4 flex items-center justify-between sm:px-0">
            <div className="-mt-px w-0 flex-1 flex">
                <div
                    onClick={previous_number}
                    className={`cursor-pointer border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm font-medium ${currentPage === 1 ? 'text-gray-700' : 'text-gray-300 hover:text-gray-700 hover:border-gray-300'}`}
                >
                    <ArrowLeftCircleIcon className="mr-3 h-5 w-5" aria-hidden="true" />
                    Anterior
                </div>
            </div>
            {getNumbers()}
            <div className="-mt-px w-0 flex-1 flex justify-end">
                <div
                    onClick={next_number}
                    className={`cursor-pointer border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm font-medium ${currentPage === totalPages ? 'text-gray-700' : 'text-gray-300 hover:text-gray-700 hover:border-gray-300'}`}
                >
                    Siguiente
                    <ArrowRightCircleIcon className="ml-3 h-5 w-5" aria-hidden="true" />
                </div>
            </div>
        </nav>
    )
}

export default SmallSetPagination