import { Link } from 'react-router-dom'
import * as dayjs from 'dayjs'
import "dayjs/locale/es"
dayjs().format()
dayjs.locale('es')


  
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }
  
  export default function BlogList({posts}) {
    return (
      <section className='bg-no-repeat bg-cover bg-center bg-background'>
        <div className='content mx-auto max-w-7xl'>
          <div className="px-10 py-24 ">
            <div>
              <h2 className="flex justify-center text-4xl font-bold tracking-tight text-gray-100">BLOG</h2>
            </div>
            <div className="grid mt-8 grid-cols-1 lg:grid-cols-3 md:grid-cols-2 lg:gap-x-5">
              {posts && posts.slice(0, 3).map((post) => (
                <div key={post.title} className="bg-gray-200 shadow-card">
                  <div className="h-52 w-full">
                    <img
                      className="h-full w-full object-cover"
                      src={`${process.env.REACT_APP_API_URL}/${post.thumbnail}`}
                      alt="" />
                  </div>
                  <div className='card-body px-4 py-6'>
                    <p className="text-xl font-semibold text-black">{post.title}</p>
                    <a href={post.href} className="block">
                    <span className='capitalize'>{dayjs(post.published).format('MMM DD, YYYY')}</span>
                    <p className="mt-3 text-base text-gray-500">{post.description}</p>
                    </a>
                    <div className="flex justify-center mt-2">
                      <Link to={`/blog/${post.slug}`}>
                        <span className="bg-gray-500 text-white px-7 py-2">Ver post</span>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    )
  }