import Footer from "components/navigation/Footer"
import Navbar from "components/navigation/Navbar"
import Layout from "hocs/layouts/Layout"
import { useEffect } from "react"
import { Helmet } from 'react-helmet-async';

function Contact(){
    useEffect(()=>{
        window.scrollTo(0,0)
    },[])
    return(
        <Layout>
            <Helmet>
        <title>Arvey Duque Villamizar | Contact</title>
        <meta name="description" content="Fundador de la Fundación Arduvi que como propósito incentivar el deporte y crear unidades productivas para personas con alguna deshabilidad." />
        <meta name="robots" content='all' />
        <link rel="canonical" href="https://www.arveyduquevillamizar.com" />
        <meta name="author" content='Arvey Duque Villamizar' />
        <meta name="publisher" content='Arvey Duque Villamizar' />

        {/* Social Media Tags */}
        <meta property="og:title" content='Arvey Duque Villamizar' />
        <meta property="og:description" content='Fundador de la Fundación Arduvi que como propósito incentivar el deporte y crear unidades productivas para personas con alguna deshabilidad' />
        <meta property="og:url" content="https://www.arveyduquevillamizar.com" />
        <meta property="og:image" content='https://bafybeicwrhxloesdlojn3bxyjqnxgsagtd4sl53a7t4cn4vfe2abmybzua.ipfs.w3s.link/lightbnuilbg.jpg' />

        <meta name="twitter:title" content='Arvey Duque Villamizar' />
        <meta
            name="twitter:description"
            content='Fundador de la Fundación Arduvi que como propósito incentivar el deporte y crear unidades productivas para personas con alguna deshabilidad.'
        />
        <meta name="twitter:image" content='https://bafybeicwrhxloesdlojn3bxyjqnxgsagtd4sl53a7t4cn4vfe2abmybzua.ipfs.w3s.link/lightbnuilbg.jpg' />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
            <Navbar/>
            <div className="pt-28">
                Contact
            </div>
                <Footer/>
        </Layout>
    )
}
export default Contact