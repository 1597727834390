import Navbar from "components/navigation/Navbar";
import Layout from "hocs/layouts/Layout";
import {Link} from 'react-router-dom'

function Error404(){
    return(
        <Layout>
        <Navbar />

        <div className="flex flex-col justify-center h-screen gap-4 colorprincipal">
           <h1 className="text-lg font-extrabold text-center text-white lg:text-8xl">404</h1> 
           <h1 className="text-lg font-extrabold text-center text-white lg:text-6xl">¡Oops!</h1> 
           <h2 className="text-lg font-extrabold text-center text-white lg:text-5xl">La página que estás buscando no fué encontrada.</h2> 
           <div className="flex justify-center">
           <Link to='/'>
           <button className="btn btn-wide colorprincipal text-white border-white hover:bg-white hover:text-black">Volver al inicio</button>
            </Link>
            </div>
        </div>
        
        </Layout>
    )
}
export default Error404