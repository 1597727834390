import { connect } from "react-redux";
import { Popover, Transition } from "@headlessui/react";
import { useState, Fragment } from "react";
import { NavLink, Link } from "react-router-dom";
import Firma from "../../assets/img/logo-1.png";

const solutions = [
  {
    name: "Inicio",
    href: "/#inicio",
  },
  {
    name: "Sobre Mi",
    href: "/#sobremi",
  },
  {
    name: "Blog",
    href: "/blog",
  },
];

function Navbar() {
  const [loading, setLoading] = useState(true);

  const [open, setOpen] = useState(false);

  return (
    <nav
      data-scroll
      data-scroll-id="hey"
      id="navbar"
      className="fixed top-0 z-40 w-full py-2 transition duration-500 ease-in-out colorsecundario flex items-center"
    >
      <div className="container mx-auto max-w-7xl">
        <div className="hidden px-2 -mt-2 -ml-4 lg:flex lg:items-center lg:justify-between md:px-4">
          <Link to="/" className="mt-2 ml-4">
            <img src={Firma} width={80} height={80} className="" />
          </Link>
          <div className="flex items-center gap-x-6">
            <a
              href="/#inicio"
              className="inline-flex font-semibold transition duration-300 ease-in-out border-b-2 border-transparent hover:border-white text-white"
            >
              Inicio
            </a>
            <a
              href="/#sobremi"
              className="inline-flex font-semibold transition duration-300 ease-in-out border-b-2 border-transparent hover:border-white text-white"
            >
              Sobre mi
            </a>
            <NavLink
              to="/blog"
              className="inline-flex font-semibold transition duration-300 ease-in-out border-b-2 border-transparent hover:border-white text-white"
            >
              Blog
            </NavLink>
          </div>
        </div>
        <div className="flex items-center justify-between px-2 lg:hidden sm:flex-nowrap">
          <Link to="/" className="">
            <img src={Firma} width={80} height={80} className="" />
          </Link>
          <div className="flex-shrink-0">
            <Popover className="relative">
              {({ open }) => (
                <>
                  <Popover.Button
                    className={`
                                  ${open ? "" : "text-opacity-90"}
                                  focus:ring-none focus:outline-none`}
                  >
                    {open ? (
                      <i className="mt-1 text-4xl bx bx-x text-white"></i>
                    ) : (
                      <i className="mt-1 text-4xl bx bx-menu text-white"></i>
                    )}
                  </Popover.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 transform -translate-x-1/2 -left-32 sm:px-0 lg:max-w-3xl">
                      <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                        <div className="bg-white lg:grid-cols-2">
                          <ul className="menu">
                            {solutions.map((item) => (
                              item.children ? (
                                <li key={item.name} className="">
                                  <details>
                                    <summary>{item.name}</summary>
                                    <ul className="">
                                      {item.children.map((child) => (
                                        <li key={child.name} className="">
                                          <a href={child.href} className="transition duration-150 ease-in-out rounded-lg hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-black focus-visible:ring-opacity-50">
                                            {child.name}
                                          </a>
                                        </li>
                                      ))}
                                    </ul>
                                  </details>
                                  
                                </li>
                              ) : (
                                <li key={item.name} className="">
                                  <a href={item.href} className="transition duration-150 ease-in-out rounded-lg hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-black focus-visible:ring-opacity-50">
                                    {item.name}
                                  </a>
                                </li>
                              )
                            ))}
                          </ul>
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover>
          </div>
        </div>
      </div>
    </nav>
  );
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(Navbar);
