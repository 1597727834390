import SmallSetPagination from "components/pagination/SmallSetPagination";
import BlogCardHorizontal from "./BlogCardHorizontal";

function BlogList({ posts, get_blog_list_page, count }) {
	return (
		<div className="overflow-hidden px-8">
			<div className="grid my-8 grid-cols-1 lg:grid-cols-3 md:grid-cols-2 lg:gap-5">
				{posts && posts.map((post, index) => (
					<BlogCardHorizontal data={post} key={index} index={index} />
				))}
			</div>
			<SmallSetPagination
				list_page={get_blog_list_page}
				list={posts}
				count={count}
			/>
		</div>
	);
}
export default BlogList;
