import Header from "components/home/Header";
import AboutMe from "components/home/AboutMe";
import Navbar from "components/navigation/Navbar";
import Layout from "hocs/layouts/Layout";
import Footer from "components/navigation/Footer";
import { useEffect } from "react";
import { get_blog_list } from "redux/actions/blog/blog";
import { connect } from "react-redux";
import BlogList from "components/home/BlogList";

function Home({ get_blog_list, posts }) {
  useEffect(() => {
    window.scrollTo(0, 0);
    get_blog_list();
  }, [get_blog_list]);

  return (
    <Layout>
      <Navbar />
      <div data-scroll-section className="">
        <Header />
        <AboutMe />
        <BlogList posts={posts && posts} />
      </div>
      <Footer />
    </Layout>
  );
}
const mapStateToProps = (state) => ({
  posts: state.blog.blog_list
})
export default connect(mapStateToProps, { get_blog_list })(Home);

