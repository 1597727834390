import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom'
import store from './store';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';


import AnimatedRoutes from 'Routes';


function App() {
  
  return (
    <HelmetProvider>
      <Helmet>
        <title>Arvey Duque Villamizar | Inicio</title>
        <meta name="description" content="Fundador de la Fundación Arduvi que como propósito incentivar el deporte y crear unidades productivas para personas con alguna deshabilidad." />
        <meta name="robots" content='all' />
        <link rel="canonical" href="https://www.arveyduquevillamizar.com" />
        <meta name="author" content='Arvey Duque Villamizar' />
        <meta name="publisher" content='Arvey Duque Villamizar' />

        {/* Social Media Tags */}
        <meta property="og:title" content='Arvey Duque Villamizar' />
        <meta property="og:description" content='Fundador de la Fundación Arduvi que como propósito incentivar el deporte y crear unidades productivas para personas con alguna deshabilidad' />
        <meta property="og:url" content="https://www.arveyduquevillamizar.com" />
        <meta property="og:image" content='https://bafybeicwrhxloesdlojn3bxyjqnxgsagtd4sl53a7t4cn4vfe2abmybzua.ipfs.w3s.link/lightbnuilbg.jpg' />

        <meta name="twitter:title" content='Arvey Duque Villamizar' />
        <meta
            name="twitter:description"
            content='Fundador de la Fundación Arduvi que como propósito incentivar el deporte y crear unidades productivas para personas con alguna deshabilidad.'
        />
        <meta name="twitter:image" content='https://bafybeicwrhxloesdlojn3bxyjqnxgsagtd4sl53a7t4cn4vfe2abmybzua.ipfs.w3s.link/lightbnuilbg.jpg' />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Provider store={store}>
        <Router>

            <AnimatedRoutes/>
        </Router>
      </Provider>
    </HelmetProvider>
  );
}

export default App;
