import Footer from "components/navigation/Footer";
import Navbar from "components/navigation/Navbar";
import Layout from "hocs/layouts/Layout";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { get_categories } from "redux/actions/categories/categories";
import { connect } from "react-redux";
import { get_blog_list, get_blog_list_page } from "redux/actions/blog/blog";
import {Link} from 'react-router-dom'
import CategoriesHeader from "components/blog/CategoriesHeader";
import BlogList from "components/blog/BlogList";

function Blog({
  get_categories,
  categories,
  get_blog_list,
  get_blog_list_page,
  posts,
  count,
}) {
  useEffect(() => {
    window.scrollTo(0, 0);
    get_categories();
    get_blog_list();
  }, []);

  return (
    <Layout>
      <Helmet>
        <title>Arvey Duque Villamizar | Blog</title>
        <meta
          name="description"
          content="Fundador de la Fundación Arduvi que como propósito incentivar el deporte y crear unidades productivas para personas con alguna deshabilidad."
        />
        <meta name="robots" content="all" />
        <link rel="canonical" href="https://www.arveyduquevillamizar.com" />
        <meta name="author" content="Arvey Duque Villamizar" />
        <meta name="publisher" content="Arvey Duque Villamizar" />

        {/* Social Media Tags */}
        <meta property="og:title" content="Arvey Duque Villamizar" />
        <meta
          property="og:description"
          content="Fundador de la Fundación Arduvi que como propósito incentivar el deporte y crear unidades productivas para personas con alguna deshabilidad"
        />
        <meta
          property="og:url"
          content="https://www.arveyduquevillamizar.com"
        />
        <meta
          property="og:image"
          content="https://bafybeicwrhxloesdlojn3bxyjqnxgsagtd4sl53a7t4cn4vfe2abmybzua.ipfs.w3s.link/lightbnuilbg.jpg"
        />

        <meta name="twitter:title" content="Arvey Duque Villamizar" />
        <meta
          name="twitter:description"
          content="Fundador de la Fundación Arduvi que como propósito incentivar el deporte y crear unidades productivas para personas con alguna deshabilidad."
        />
        <meta
          name="twitter:image"
          content="https://bafybeicwrhxloesdlojn3bxyjqnxgsagtd4sl53a7t4cn4vfe2abmybzua.ipfs.w3s.link/lightbnuilbg.jpg"
        />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Navbar />
      {categories && posts ? (
        <div className="pt-24 colorprincipal min-h-screen pb-4">
          <CategoriesHeader categories={categories && categories} />
          <div className="px-4 sm:px-6 lg:px-8 my-6">
            {/* We've used 3xl here, but feel free to try other max-widths based on your needs */}
            <div className="mx-auto max-w-6xl">
              {/* Content goes here */}
              <BlogList
                posts={posts && posts}
                get_blog_list_page={get_blog_list_page}
                count={count && count}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="pt-24 min-h-screen pb-4">
          <span className="text-white text-lg flex justify-center mt-20 text-center md:text-4xl">En este momento no hay publicaciones disponibles.</span>
          <span className="text-white text-lg flex justify-center mt-2 text-center md:text-4xl">¡Gracias por visitarnos!</span>
          <Link to='/'>
          <button className="btn btn-wide text-white border-white flex m-auto justify-center mt-6">Volver al inicio</button>
          </Link>
        </div>
      )}
      <Footer />
    </Layout>
  );
}
const mapStateToProps = (state) => ({
  categories: state.categories.categories,
  posts: state.blog.blog_list,
  count: state.blog.count,
  next: state.blog.next,
  previous: state.blog.previous,
});

export default connect(mapStateToProps, {
  get_categories,
  get_blog_list,
  get_blog_list_page,
})(Blog);
